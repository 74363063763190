import React from "react";
import '../../asset/css/TermsAndConditions.css';

const TermsAndConditions = () => {

    return (
        <div className="outer-container">
            <div className="inner-container">
                <h1>Terms and Conditions for Hungreli</h1>

                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <strong>
                        Welcome to Hungreli! Please read these terms and conditions carefully before using our services. By accessing or using the Hungreli platform, you agree to be bound by these terms. If you do not agree to any part of these terms, you may not use our services.
                    </strong>
                </div>

                    <>
                        <h2 id="terms">Definitions</h2>
                        <ul>
                            <li><p><strong>Hungreli : </strong> refers to the food delivery service operated by Our Company.</p></li>
                            <li><p><strong>Platform : </strong> includes the Hungreli website, mobile applications, and related services.</p></li>
                            <li><p><strong>User : </strong> refers to any person accessing or using the Hungreli platform.</p></li>
                            <li><p><strong>Restaurant Partner : </strong> refers to the eateries or food providers listed on the platform.</p></li>
                            <li><p><strong>Delivery Partner : </strong> refers to individuals delivering orders placed via Hungreli.</p></li>
                        </ul>

                        <h2>Eligibility</h2>
                        <ul>
                            <li><p>Users must be at least 18 years old or have parental/guardian consent to use the platform.</p></li>
                            <li><p>By using the platform, you represent that you have the legal capacity to enter into a binding agreement.</p></li>
                        </ul>

                        <h2>Registration and Account</h2>
                        <ul>
                            <li><p>Users must register and maintain a personal account to place orders.</p></li>
                            <li><p>You are responsible for maintaining the confidentiality of your account credentials.</p></li>
                            <li><p>Hungreli reserves the right to suspend or terminate accounts in case of unauthorized activities or breaches of these terms.</p></li>
                        </ul>

                        <h2>Services</h2>
                        <ul>
                            <li><p>Hungreli acts as a facilitator between users, restaurant partners, and delivery partners.</p></li>
                            <li><p>Hungreli does not own, prepare, or control the food and beverages listed on the platform.</p></li>
                            <li><p>Orders are subject to availability and the operational hours of restaurant partners.</p></li>
                        </ul>

                        <h2>Pricing and Payment</h2>
                        <ul>
                            <li><p>Prices displayed on the platform are determined by the restaurant partners and are subject to change without notice.</p></li>
                            <li><p>All payments must be made through the available payment methods on the platform.</p></li>
                            <li><p>Hungreli may charge a delivery fee or convenience fee, which will be disclosed during the checkout process.</p></li>
                        </ul>
                        
                    </>
                    
                <h2>Contact Information</h2>
                <p>For questions or concerns, contact Hungreli at:</p>
                <p>Email: <a href="mailto:info.hungreli@gmail.com">info.hungreli@gmail.com</a></p>
                <p style={{ marginBottom: '100px' }}>Phone: +91-9004656560</p>
            </div>
        </div>
    );
};

export default TermsAndConditions;
