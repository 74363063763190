import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../asset/css/DeliveryDash.css";

const DeliveryDash = () => {
  const [deliveryData, setDeliveryData] = useState(null);
  const [error, setError] = useState("");

  const fetchDeliveryStatus = async () => {
    try {
      const deliveryBoyId = localStorage.getItem("deliveryBoyId"); // Fetch ID
      if (!deliveryBoyId) {
        setError("No delivery boy logged in.");
        return;
      }

      const response = await axios.get("https://hungreli.com/api/getdeliverystatus.php", {
        params: { deliveryBoyId: deliveryBoyId }, // Pass partner ID as query parameter
      });

      setDeliveryData(response.data);
    } catch (err) {
      setError(err.response?.data?.message || "Error fetching delivery status.");
    }
  };

  useEffect(() => {
    fetchDeliveryStatus();

    // Polling every 10 seconds for updates (or use WebSocket for real-time updates)
    const interval = setInterval(fetchDeliveryStatus, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="delivery-dash">
      {/* Header */}
      <header className="header">
        <h1>Welcome to Delivery Dashboard</h1>
        <p>Manage your deliveries efficiently!</p>
      
      </header>

      {/* Status Table */}
      <div className="status-table">
        {error ? (
          <p className="error">{error}</p>
        ) : deliveryData ? (
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{deliveryData.id}</td>
                <td>{deliveryData.status}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default DeliveryDash;
