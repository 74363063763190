import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../../asset/css/ForgetPasswordPage..css";

function ForgetPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handleForgetPassword = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://hungreli.com/api/forget_password.php",
        { email }
      );

      setMessage(response.data.message);
    } catch (err) {
      setMessage("An error occurred. Please try again.");
    }
  };

  return (
    <div className="forget-password-page">
      {/* Header */}
      <div className="dashboard-header">
        <h1 className="dashboard-heading">Restaurant Dashboard</h1>
        {/* Displaying the status */}
      </div>

      {/* Main Content */}
      <main className="">
        <div className="card">
          <h2>Reset Your Password</h2>
          <p>Please enter your registered email to receive a reset link.</p>
          <form onSubmit={handleForgetPassword}>
            <div className="form-group">
              <label>Email Address</label>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Send Reset Link
            </button>
          </form>
          {message && <div className="alert mt-3">{message}</div>}
          <button
            type="button"
            className="btn btn-secondary mt-3"
            onClick={handleBack}
          >
            Back
          </button>
        </div>
      </main>
    </div>
  );
}

export default ForgetPassword;
