import React from "react";
import '../../asset/css/TermsAndConditions.css';

const Privacypolicy = () => {
    // const [activeSection, setActiveSection] = useState('terms');


    return (
        <div className="outer-container">
            <div className="inner-container">
                <h1>Privacy Policy for Hungreli</h1>

                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <strong>
                        Welcome to Hungreli! Please read these terms and conditions carefully before using our services. By accessing or using the Hungreli platform, you agree to be bound by these terms. If you do not agree to any part of these terms, you may not use our services.
                    </strong>
                </div>
                
                    <>
                        <h2 id="privacy-policy">Privacy Policy</h2>
                        <ul>
                            <li><p>Hungreli reserves the right to terminate or restrict access to the platform for any user violating these terms.</p></li>
                        </ul>
                        

                        <h2>Governing Law and Dispute Resolution</h2>
                        <ul>
                            <li><p>These terms shall be governed by the laws of Our Jurisdiction.</p></li>
                            <li><p>Disputes shall be resolved through arbitration or mediation before pursuing legal action.</p></li>
                        </ul>

                        <h2>Amendments</h2>
                        <ul>
                            <li><p>Hungreli reserves the right to amend these terms at any time. Users will be notified of significant changes, and continued use of the platform constitutes acceptance of the updated terms.</p></li>
                        </ul>
                    </>

                
                <h2>Contact Information</h2>
                <p>For questions or concerns, contact Hungreli at:</p>
                <p>Email: <a href="mailto:info.hungreli@gmail.com">info.hungreli@gmail.com</a></p>
                <p style={{ marginBottom: '100px' }}>Phone: +91-9004656560</p>
            </div>
        </div>
    );
};

export default Privacypolicy;
