import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../../asset/css/restaurantFood.css";

const RestaurantFood = () => {
  const [formData, setFormData] = useState({
    foodName: "",
    category: "",
    price: "",
    discountPrice: "",
    images: [],
    inStock: true,
    description: "",
    restaurant_partner_id: "", 
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      images: [...e.target.files],
    });
  };

  const handleToggleStock = () => {
    setFormData({
      ...formData,
      inStock: !formData.inStock,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const foodData = new FormData();
      foodData.append("foodName", formData.foodName);
      foodData.append("category", formData.category);
      foodData.append("price", formData.price);
      foodData.append("discountPrice", formData.discountPrice);
      foodData.append("inStock", formData.inStock);
      foodData.append("description", formData.description);
      foodData.append("restaurant_partner_id", formData.restaurant_partner_id); 
      Array.from(formData.images).forEach((file) => {
        foodData.append("images", file);
      });

      const response = await axios.post("https://hungreli.com/api/restaurantfood.php", foodData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response);

      if (response.data.status === "success") {
        setSuccessMessage("Food item has been registered successfully!");
        setErrorMessage("");
        setTimeout(() => {
          navigate("/RestaurantDash");
        }, 2000); // 2-second delay
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      console.error("Error submitting food data:", error);
      setErrorMessage("Failed to register food item. Please try again.");
    }
  };

  return (
    <div className="restaurant-food-form-container">
      <form onSubmit={handleSubmit} className="restaurant-food-form">
        <div className="form-group">
          <label htmlFor="foodName">Food Name:</label>
          <input
            type="text"
            id="foodName"
            name="foodName"
            value={formData.foodName}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="category">Category:</label>
          <select
            id="category"
            name="category"
            value={formData.category}
            onChange={handleInputChange}
            required
          >
            <option value="">Select Category</option>
            <option value="Veg">Veg</option>
            <option value="Non-veg">Non-Veg</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="price">Price:</label>
          <input
            type="number"
            id="price"
            name="price"
            placeholder="Rs."
            value={formData.price}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="discountPrice">Discount Price:</label>
          <input
            type="number"
            id="discountPrice"
            name="discountPrice"
            placeholder="Rs."
            value={formData.discountPrice}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="restaurant_partner_id">Restaurant Partner ID:</label>
          <input
            type="Text"
            id="restaurant_partner_id"
            name="restaurant_partner_id"
            value={formData.restaurant_partner_id}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="images">Food Images (Multiple):</label>
          <input
            type="file"
            id="images"
            name="images[]"
            accept="image/*"
            multiple
            onChange={handleFileChange}
          />
          <div className="image-preview-container">
            {formData.images.length > 0 &&
              formData.images.map((image, index) => (
                <div key={index} className="image-preview">
                  <img src={URL.createObjectURL(image)} alt="Food Preview" />
                </div>
              ))}
          </div>
        </div>

        <div className="form-group">
          <label>In Stock:</label>
          <button
            type="button"
            className={`toggle-button ${formData.inStock ? "active" : "inactive"}`}
            onClick={handleToggleStock}
          >
            {formData.inStock ? "In Stock" : "Out of Stock"}
          </button>
        </div>

        <div className="form-group">
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            placeholder="Enter a short description of the food item"
            rows="3"
            required
          ></textarea>
        </div>

        <button type="submit" className="register-btn">
          Register Food Item
        </button>
      </form>

      {/* Display success or error message */}
      {successMessage && <div className="alert alert-success">{successMessage}</div>}
      {errorMessage && <div className="alert alert-error">{errorMessage}</div>}
    </div>
  );
};

export default RestaurantFood;
