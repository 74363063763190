import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

function ResetPasswordPage() {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post(
        "https://hungreli.com/api/reset_password.php",
        { token, new_password: newPassword }
      );

      setMessage(response.data.message);
    } catch (err) {
      setMessage("An error occurred. Please try again.");
    }
  };

  return (
    <div className="reset-page">
      {/* Header */}
      <div className="dashboard-header">
        <h1 className="dashboard-heading">Restaurant Reset Password</h1>
        {/* Displaying the status */}
      </div>

      {/* Main Content */}
      <main className="">
        <div className="card">
          <h2>Reset Password</h2>
          <form onSubmit={handleResetPassword}>
            <div className="form-group">
              <label>New Password</label>
              <input
                type="password"
                className="form-control"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Confirm Password</label>
              <input
                type="password"
                className="form-control"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Reset Password
            </button>
          </form>
          {message && <div className="alert mt-3">{message}</div>}
        </div>
      </main>
    </div>
  );
}

export default ResetPasswordPage;
