import React, { useState } from "react";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "../../asset/css/RestaurantPartnerPage.css";

const loggedInUser = JSON.parse(sessionStorage.getItem("user"));
console.log("loggedInUser");
console.log(loggedInUser);
function RestaurantPartnerPage() {

  const [form, setForm] = useState({
    restaurantName: "",
    ownerName: "",
    restaurantPartnerId: "",
    email: "",
    password: "",
    contactNumber: "",
    address: "",
    cuisineType: "",
    gstNumber: "",
    foodLicense: "",
    declarationAccepted: false,
    statusrestaurant: 'Pending'
  });
  const [isLogin, setIsLogin] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [usernameMessage, setUsernameMessage] = useState(""); 
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const checkUsernameAvailability = async () => {
    if (!form.restaurantPartnerId) return;

    try {
      const response = await axios.post(
        "https://hungreli.com/api/checkrestuser.php",
        { restaurantPartnerId: form.restaurantPartnerId }
      );

      if (response.data.status === "exists") {
        setUsernameMessage(response.data.message); // Username exists
      } else {
        setUsernameMessage(""); // Username is available
      }
    } catch (err) {
      setUsernameMessage("Error checking username availability.");
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    try {
      if (isLogin) {
        // Login logic
        const response = await axios.post(
          "https://hungreli.com/api/restaurant_login.php",
          {
            email: form.email,
            password: form.password,
          }
        );

        console.log(response.data);
        if (response.data.status === "success") {
          console.log(response.data.restaurantPartnerId);
          // sessionStorage.setItem("restaurantPartnerId", JSON.stringify(restaurantPartnerId)); 
          sessionStorage.setItem("restaurantPartnerId", response.data.restaurantPartnerId.restaurant_partner_id);
          // Store restaurant_partner_id in localStorage
          localStorage.setItem("restaurant_partner_id", response.data.restaurantPartnerId.restaurant_partner_id);


          console.log("ID saved:", response.data.restaurantPartnerId);  // Ensure this is logged
          setMessage(response.data.message);
          navigate("/RestaurantDash");
        }
      } else {
        // Registration logic
        if (!form.declarationAccepted) {
          setMessage("You must accept the declaration to proceed.");
          return;
        }

        const response = await axios.post(
          "https://hungreli.com/api/restaurant_register.php",
          form
        );

        if (response.data.status === "success") {
          setMessage("Registration successful! Please login.");
          // navigate("/RegFeePay"); // Redirect to RegFeePay.js
          setIsLogin(true); // Switch to login mode
        }
      }
    } catch (err) {
      setMessage(err.response?.data?.message || "An error occurred!");
    }
  };

  return (
      <div className={`${isLogin === "Restaurant Partner Login" ? "card p-4" : "cardrpp p-4"}`}>
      <h2>{isLogin ? "Restaurant Partner Login" : "Restaurant Partner Registration"}</h2>
      <form onSubmit={handleSubmit}>
        {!isLogin && (
          <>
            <div className="mb-3">
              <label className="form-label">Restaurant Name</label>
              <input
                type="text"
                className="form-control"
                name="restaurantName"
                value={form.restaurantName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Owner Name</label>
              <input
                type="text"
                className="form-control"
                name="ownerName"
                value={form.ownerName}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Restaurant ID</label>
              <input
                type="text"
                className={`form-control ${
                  usernameMessage ? "is-invalid" : ""
                }`}
                name="restaurantPartnerId"
                value={form.restaurantPartnerId}
                onChange={handleChange}
                onBlur={checkUsernameAvailability} // Check username on blur
                required
              />
              {usernameMessage && (
                <div className="invalid-feedback">{usernameMessage}</div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Contact Number</label>
              <input
                type="text"
                className="form-control"
                name="contactNumber"
                value={form.contactNumber}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Address</label>
              <input
                type="text"
                className="form-control"
                name="address"
                value={form.address}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Cuisine Type</label>
              <input
                type="text"
                className="form-control"
                name="cuisineType"
                value={form.cuisineType}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">GST Number</label>
              <input
                type="text"
                className="form-control"
                name="gstNumber"
                value={form.gstNumber}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Food License</label>
              <input
                type="text"
                className="form-control"
                name="foodLicense"
                value={form.foodLicense}
                onChange={handleChange}
                required
              />
            </div>
          </>
        )}
        <div className="mb-3">
          <label className="form-label">Email</label>
          <input
            type="email"
            className="form-control"
            name="email"
            value={form.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Password</label>
          <div className="input-group">
            <input
              type={passwordVisible ? "text" : "password"}
              className="form-control"
              name="password"
              value={form.password}
              onChange={handleChange}
              required
            />
            <span
              className="input-group-text"
              onClick={togglePasswordVisibility}
              style={{ cursor: "pointer" }}
            >
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </div>
        {!isLogin && (
          <div className="mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              name="declarationAccepted"
              checked={form.declarationAccepted}
              onChange={handleChange}
              required
            />
            <label className="form-check-label">
              I declare that the information provided is true and accurate.
            </label>
          </div>
        )}
        <button type="submit" className="btn btn-primary">
          {isLogin ? "Login" : "Register"}
        </button>
        
        {/* <div className="mt-3">
          <button
            type="button"
            className="btn btn-link"
            onClick={() => {
              setIsLogin(!isLogin);
              setMessage("");
            }}
          >
            {isLogin
              ? "Don't have an account? Register"
              : "Already have an account? Login"}
          </button>
        </div> */}
        <div className="mt-3">
        <button
          type="button"
          className="btn btn-link"
          onClick={() => {
            setIsLogin(!isLogin);
            setMessage("");
          }}
        >
          {isLogin
            ? "Don't have an account? Register"
            : "Already have an account? Login"}
        </button>
        {isLogin && (
          <button
            type="button"
            className="btn btn-link"
            onClick={() => navigate("/ForgetPassword")}
          >
            Forget Password?
          </button>
        )}
      </div>

      </form>
      {message && <div className="alert alert-info mt-3">{message}</div>}
    </div>
  );
}

export default RestaurantPartnerPage;