import React, { useState, useEffect } from "react";
import axios from "axios";

const AdminProfile = () => {
  const [adminDetails, setAdminDetails] = useState({
    profilePic: "",
    name: "",
    email: "",
    phone: "",
    address: "",
  });

  const [isEditing, setIsEditing] = useState(false);
  const [formDetails, setFormDetails] = useState(adminDetails);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch admin details from API
    const fetchAdminDetails = async () => {
      try {
        const response = await axios.get("https://hungreli.com/api/getadmindetails.php");
        setAdminDetails(response.data);
        setFormDetails(response.data);
      } catch (error) {
        console.error("Error fetching admin details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAdminDetails();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormDetails({ ...formDetails, [name]: value });
  };

  const handleSave = async () => {
    try {
      const response = await axios.put("https://hungreli.com/api/getadmindetails.php", formDetails);
      setAdminDetails(response.data);
      setIsEditing(false);
    } catch (error) {
      console.error("Error saving admin details:", error);
    }
  };

  // const handleImageUpload = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = () => setFormDetails({ ...formDetails, profilePic: reader.result });
  //     reader.readAsDataURL(file);
  //   }
  // };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="admin-profile card p-4">
      <h2>Admin Profile</h2>
      {/* <div className="profile-picture-section text-center mb-4">
        <label htmlFor="profilePicUpload" className="d-block">
          <img
            src={formDetails.profilePic || "https://via.placeholder.com/150"}
            alt="Profile"
            className="rounded-circle"
            style={{ width: "150px", height: "150px", objectFit: "cover" }}
          />
        </label>
        {isEditing && (
          <input
            type="file"
            id="profilePicUpload"
            className="form-control mt-2"
            onChange={handleImageUpload}
            accept="image/*"
          />
        )}
      </div> */}

      <div className="profile-details">
        {isEditing ? (
          <>
            <div className="mb-3">
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={formDetails.name}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={formDetails.email}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            {/* <div className="mb-3">
              <label>Phone</label>
              <input
                type="tel"
                name="phone"
                value={formDetails.phone}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label>Address</label>
              <input
                type="text"
                name="address"
                value={formDetails.address}
                onChange={handleInputChange}
                className="form-control"
              />
            </div> */}
          </>
        ) : (
          <>
            <p>
              <strong>Name:</strong> {adminDetails.username}
            </p>
            <p>
              <strong>Email:</strong> {adminDetails.email}
            </p>
            {/* <p>
              <strong>Phone:</strong> {adminDetails.phone}
            </p>
            <p>
              <strong>Address:</strong> {adminDetails.address}
            </p> */}
          </>
        )}
      </div>

      <div className="action-buttons mt-4">
        {isEditing ? (
          <>
            <button className="btn btn-success me-2" onClick={handleSave}>
              Save
            </button>
            <button className="btn btn-secondary" onClick={() => setIsEditing(false)}>
              Cancel
            </button>
          </>
        ) : (
          <button className="btn btn-primary" onClick={() => setIsEditing(true)}>
            Edit Profile
          </button>
        )}
      </div>
    </div>
  );
};

export default AdminProfile;
