import React, { useState } from "react";
import axios from "axios";
import "../../asset/css/AdminPage.css";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function Adminregistration() {
  const [form, setForm] = useState({ username: "", email: "", password: "" });
  const [message, setMessage] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [emailForReset, setEmailForReset] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");

    try {
      const response = await axios.post("https://hungreli.com/api/admin_register.php", form);

      if (response.data.status === "success") {
        setMessage(response.data.message);
        navigate("/AdminDashboard");
      } else {
        setMessage("Unexpected response: " + response.data.message);
      }
    } catch (err) {
      setMessage(err.response?.data?.message || "An error occurred!");
    }
  };

  const handleForgotPassword = async () => {
    try {
      const response = await axios.post(
        "https://hungreli.com/api/admin/forgot-password",
        { email: emailForReset }
      );

      if (response.data.status === "success") {
        setMessage("Password reset email sent successfully!");
        setShowForgotPassword(false);
      } else {
        setMessage("Error: " + response.data.message);
      }
    } catch (err) {
      setMessage(err.response?.data?.message || "An error occurred!");
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="card p-4">
      <h2>Admin Registration</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label>Username</label>
          <input
            type="text"
            name="username"
            className="form-control"
            value={form.username}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label>Email</label>
          <input
            type="email"
            name="email"
            className="form-control"
            value={form.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label>Password</label>
          <div className="input-group">
            <input
              type={passwordVisible ? "text" : "password"}
              name="password"
              className="form-control"
              value={form.password}
              onChange={handleChange}
              required
            />
            <span className="input-group-text" onClick={togglePasswordVisibility}>
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </div>
        <button type="submit" className="btn btn-primary">
          Register
        </button>
      </form>
      <button
        type="button"
        className="btn btn-link mt-3"
        onClick={() => setShowForgotPassword(true)}
      >
        Forgot Password?
      </button>
      {message && <div className="alert mt-3">{message}</div>}

      {showForgotPassword && (
        <div className="modal">
          <div className="modal-content">
            <h3>Reset Password</h3>
            <input
              type="email"
              placeholder="Enter your email"
              value={emailForReset}
              onChange={(e) => setEmailForReset(e.target.value)}
              className="form-control mb-3"
            />
            <button onClick={handleForgotPassword} className="btn btn-primary">
              Send Reset Link
            </button>
            <button
              onClick={() => setShowForgotPassword(false)}
              className="btn btn-secondary mt-2"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Adminregistration;
