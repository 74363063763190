import React from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, Nav, Dropdown } from "react-bootstrap"; // Using React-Bootstrap for styling
// import  // Optional CSS for custom styles
import logo from "../../asset/images/logo.webp";
import "../../asset/css/AdminNavbar.css";
const AdminNavbar = () => {
  const navigate = useNavigate();

  // Handle logout
  const handleLogout = () => {
    // Add any cleanup logic here (e.g., clearing auth tokens)
    navigate("/");
  };

  return (
    <Navbar bg="#e18d33" variant="light" expand="lg" className="admin-navbar">
      <Navbar.Brand href="/" className="logo">
      <img src={logo} alt="Hungreli Logo" height="50" />
        Hungreli 
      </Navbar.Brand>
      <Nav className="ml-auto">
        <Dropdown align="end" className="profile-dropdown">
          <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="profile-icon">
            <i className="fas fa-user-circle" style={{ fontSize: "1.5rem" }}></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {/* <Dropdown.Item onClick={() => navigate("/Adminregistration")}>
              Add Admin User's
            </Dropdown.Item> */}
            <Dropdown.Item onClick={() => navigate("/adminProfile")}>
              Edit Profile
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Nav>
    </Navbar>
  );
};

export default AdminNavbar;
