import React, { useState } from "react";
import axios from "axios";
import "../../asset/css/AdminPage.css";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function AdminPage() {
  const [form, setForm] = useState({ username: "", password: "" });
  const [message, setMessage] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");

    try {
      const response = await axios.post("https://hungreli.com/api/admin_login.php", form);

      if (response.data.status === "success") {
        setMessage(response.data.message);
        navigate("/AdminDashboard");
      } else {
        setMessage("Unexpected response: " + response.data.message);
      }
    } catch (err) {
      setMessage(err.response?.data?.message || "An error occurred!");
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="card p-4">
      <h2>Admin Login</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label>Username</label>
          <input
            type="text"
            name="username"
            className="form-control"
            value={form.username}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label>Password</label>
          <div className="input-group">
            <input
              type={passwordVisible ? "text" : "password"}
              name="password"
              className="form-control"
              value={form.password}
              onChange={handleChange}
              required
            />
            <span className="input-group-text" onClick={togglePasswordVisibility}>
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </div>
        <button type="submit" className="btn btn-primary">
          Login
        </button>
      </form>
      {message && <div className="alert mt-3">{message}</div>}
    </div>
  );
}

export default AdminPage;

