import React, { useState, useEffect } from "react";
import axios from "axios";

const RestaurantListPage = () => {
  const [foodItems, setFoodItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFoodData = async () => {
      const loggedInPartnerId = localStorage.getItem("restaurant_partner_id");
      console.log("Logged In Partner ID:", loggedInPartnerId);

      try {
        const response = await axios.get("https://hungreli.com/api/getallfoodlist.php", {
          params: { restaurant_partner_id: loggedInPartnerId },
        });
        console.log("API Response:", response.data); // Debugging

        if (response.data.status === "success") {
          setFoodItems(response.data.data || []);
        } else {
          console.error("Failed to fetch food data:", response.data.message);
        }
      } catch (err) {
        console.error("Error fetching food data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchFoodData();
  }, []);

  if (loading) {
    return <p>Loading food items...</p>;
  }

  return (
    <div className="container">
      <h1>Your Registered Food Items</h1>
      {foodItems.length === 0 ? (
        <p>No food items found. Please register some items.</p>
      ) : (
        <ul className="food-list">
          {foodItems.map((food) => (
            <li key={food.id} className="food-item">
              <h3>{food.foodName}</h3>
              <p>Category: {food.category}</p>
              <p>Price: ₹{food.price}</p>
              <p>Discount Price: ₹{food.discountPrice}</p>
              <p
                className={
                  food.inStock === "1" ? "in-stock" : "out-of-stock"
                }
              >
                {food.inStock === "1" ? "In Stock" : "Out of Stock"}
              </p>
              <p>Description: {food.description}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default RestaurantListPage;
